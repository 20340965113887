/* tslint:disable */
/* eslint-disable */
// This file extends the amplify-generated queries file
// and customizes the fields called
// (copy over and remove unneccessary fields)

export * from '@components/graphql/generated/queries';

// explicitly export to override
/* TODO verify that we need all this data on the prospect object. */

export const listProspect = /* GraphQL */ `
    query ListVendees(
        $country_code: COUNTRY_CODE!
        $sort: SORT
        $sort_field: PROSPECT_SORT_FIELD
        $from: Int!
        $size: Int!
        $owner: [String!]
        $status: [PROSPECT_STATUS!]
        $search_term: String
        $epc: [String!]
        $epc_status: [EPC_STATUS!]
        $roof_material: [ROOF_MATERIAL!]
        $product: [PRODUCT!]
    ) {
        listVendees(
            country_code: $country_code
            sort: $sort
            sort_field: $sort_field
            from: $from
            size: $size
            owner: $owner
            status: $status
            search_term: $search_term
            epc: $epc
            epc_status: $epc_status
            roof_material: $roof_material
            product: $product
        ) {
            total
            vendees {
                id
                product
                owner
                address
                city
                state
                zip
                directory {
                    contacts {
                        name
                        family_name
                    }
                }
                simulation {
                    installation {
                        cost {
                            high
                        }
                        size
                    }
                }
                site {
                    utilities {
                        calculated
                    }
                }
                proffer {
                    comment
                    epc {
                        company {
                            value
                            label
                        }
                        index
                        status
                        capacity
                        cost
                        module
                        panel_number
                        inverter
                        inverter_number
                        scaffolding
                        generated
                        docs {
                            name
                            type
                            link
                            category
                            deleted
                            updated_at
                        }
                        comment
                        remark
                        created
                        updated
                        delivery
                        reports {
                            date
                            text
                        }
                    }
                }
                integrations {
                    trengo {
                        tickets {
                            id
                            channel
                        }
                    }
                    hubspot {
                        deal_id
                    }
                }
                status {
                    value
                    date
                }
                stages {
                    start
                }
                updated
            }
        }
    }
`;

export const listCustomers = /* GraphQL */ `
    query ListCustomers(
        $country_code: COUNTRY_CODE!
        $order: SORT
        $sort: CUSTOMER_SORT_FIELD
        $from: Int!
        $size: Int!
        $search: String
        $product: [PRODUCT!]
    ) {
        listCustomers(
            country_code: $country_code
            order: $order
            sort: $sort
            from: $from
            size: $size
            search: $search
            product: $product
        ) {
            total
            customers {
                id
                src
                ids {
                    lead
                    prospect
                    customer
                    internal
                }
                product
                address
                zip
                directory {
                    contacts {
                        id
                        name
                        family_name
                        company
                        email
                        phone
                        method
                        type
                    }
                }
                status {
                    value
                    date
                }
                validated
                tally {
                    installation
                    commissioning
                    deposit
                    lease
                    size
                    term
                    investor
                    start
                    end
                    contract_end
                    rebate
                    payment {
                        method
                        bank
                        bank_account
                        bank_owner
                    }
                    bills {
                        id
                        type
                        status
                        raw
                        total
                        GST
                        taxe
                        send_date
                        invoice_date
                        due_date
                        period_start
                        period_end
                        paid
                        rebate
                        method
                        remark
                    }
                    currency
                    status
                    epc {
                        value
                        label
                    }
                    price
                    capex
                    installation_start
                    site_visit
                    retailer
                    contract
                    whatsapp
                    ami
                    sp
                    sp_no
                    giro
                    inverter
                    inverter_type
                    inverter_number
                    panel
                    panel_number
                }
                stages {
                    status
                    start
                    end
                    duration
                    category
                    id
                }
                created {
                    vendee
                    lead
                    prospect
                    customer
                }
                updated
                comment
            }
        }
    }
`;

export const listSale = /* GraphQL */ `
    query ListSale($country_code: COUNTRY_CODE!) {
        listSale(country_code: $country_code) {
            total
            sales {
                name
                family_name
                group
                id
            }
        }
    }
`;

export const getDashboardActivitiesAndDurations = /* GraphQL */ `
    query GetDashboardActivitiesAndSales(
        $country_code: COUNTRY_CODE!
        $from: String!
        $to: String!
        $product: [PRODUCT!]
    ) {
        getDashboardActivities(
            country_code: $country_code
            product: $product
            from: $from
            to: $to
        ) {
            activity {
                status
                owner
                count
            }
        }
        getDashboardActivityDurations(
            country_code: $country_code
            product: $product
            from: $from
            to: $to
        ) {
            duration {
                status
                owner
                avg
                median
                min
                max
            }
        }
    }
`;
