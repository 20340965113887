//types
import { ListType } from '@components/lib/interfaces';
import { SelectDataType } from '@interfaces/index';

/**
 * Compute sales id as key and sales name as value
 *
 * @param {ListType} result - The list of the sales
 * @param {SelectDataType} sale - The sale to be added to the list
 * @returns {ListType} - The list of the sales
 */
const formatSales = (result: ListType, sale: SelectDataType): ListType => ({
    ...result,
    [sale.value as string]: sale.label.charAt(0).toUpperCase() + sale.label.slice(1)
});

/**
 * Format the sales list as key-value pairs
 *
 * @param {SelectDataType[]} sales - The list of sales
 * @returns {ListType} - The formatted sales list
 */
const formatSalesRsl = (sales: SelectDataType[]): ListType => {
    return sales.reduce(formatSales, {});
};

export default formatSalesRsl;
