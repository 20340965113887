//types
import { AmplifyAPI } from '@components/lib/interfaces';
import { ListSaleQuery, ListSaleQueryVariables, Profile } from 'interfaces/graphql/API';

//Library
import { GraphQLResult } from '@aws-amplify/api';
import { listSale } from '@components/graphql/queries';

/**
 * Get sale list
 *
 * @param {AmplifyAPI} API - Amplify API.
 * @param {ListSaleQueryVariables} variables - The variables.
 * @returns {Promise<Profile[]>} - The sale list.
 */
const listSaleQuery = async (
    API: AmplifyAPI,
    variables: ListSaleQueryVariables
): Promise<Profile[]> => {
    const response: GraphQLResult<ListSaleQuery> = (await API.graphql({
        query: listSale,
        variables
    })) as GraphQLResult<ListSaleQuery>;

    return response.data?.listSale.sales || [];
};

export default listSaleQuery;
