//types
import { SelectDataType } from '@interfaces/index';

/**
 * Sort the profiles in ascending order
 *
 * @param {SelectDataType} a - The former sale
 * @param {SelectDataType} b - The latter sale
 * @returns {number} - The number to indicate the sorting order
 */
const sortProfiles = (a: SelectDataType, b: SelectDataType): number =>
    a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : 0;

/**
 * @param {SelectDataType[]} sales - The list of sales
 * @returns {SelectDataType[]} - The array of formatted sale values
 */
const dashboardSalesRsl = (sales: SelectDataType[]): SelectDataType[] => {
    let dashboardSales = sales.sort(sortProfiles);

    dashboardSales = [
        {
            value: 'total',
            label: 'Total'
        },
        ...dashboardSales
    ];

    return dashboardSales;
};

export default dashboardSalesRsl;
