//types
import { SelectDataType } from '@interfaces/index';

/**
 * Get the name of the deal owner of the prospect
 *
 * @param {SelectDataType[]} sales - The list of sales
 * @param {string | null} ownerId - The deal owner of the prospect
 * @returns {string} - The name of the deal owner
 */
const computeOwnerRsl = (sales: SelectDataType[], ownerId?: string | null): string => {
    if (!ownerId) {
        return 'No Owner';
    }

    const owner = sales.find((sale) => sale.value === ownerId);

    if (!owner) {
        return 'Deleted User';
    }

    return owner.label;
};

export default computeOwnerRsl;
