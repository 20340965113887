//types
import { SelectDataType } from '@interfaces/index';

/**
 * Modify the profile into the sales list
 *
 * @param {SelectDataType[]} sales - The list of sales
 * @param {string} owner - The deal owner of the vendee
 * @returns {SelectDataType[]} - The array of formatted sale values
 */
const vendeeSalesRsl = (sales: SelectDataType[], owner: string): SelectDataType[] => {
    const deletedUser = sales.find((sale) => sale.value === owner);

    if (!deletedUser) {
        return [...sales, { value: owner, label: 'Deleted User' }];
    }

    return sales;
};

export default vendeeSalesRsl;
