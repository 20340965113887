//code
import listSaleQuery from '@components/lib/model/query/listSaleQuery';

//resolvers
import dashboardSalesRsl from '@components/lib/model/resolver/dashboardSalesRsl';
import vendeeSalesRsl from '@components/lib/model/resolver/vendeeSalesRsl';
import formatSalesRsl from '@components/lib/model/resolver/formatSalesRsl';
import computeOwnerRsl from '@components/lib/model/resolver/computeOwnerRsl';

//types
import {
    SalesProps,
    SalesModelReturn,
    GetVendeeSalesReturn
} from '@components/lib/interfaces/model/salesModel/salesModel';
import { SelectDataType } from '@interfaces/index';
import { COUNTRY_CODE, Profile } from '@interfaces/graphql/API';
import { MentionData } from '@draft-js-plugins/mention/lib';

/**
 * Sales model
 *
 * @param {SalesProps} props - The props
 * @returns {SalesModelReturn} - The model functions
 */
const salesModel = ({
    lib: {
        external: { API }
    }
}: SalesProps): SalesModelReturn => {
    /**
     * Format the list of profiles
     *
     * @param {Profile} profile - The sale profile
     * @returns {SelectDataType} - The formatted sale profile
     */
    const mapProfiles = (profile: Profile): SelectDataType => ({
        value: profile.id,
        id: profile.id,
        label: profile.name.charAt(0).toUpperCase() + profile.name.slice(1),
        name:
            profile.name.charAt(0).toUpperCase() +
            profile.name.slice(1) +
            ' ' +
            profile.family_name.charAt(0).toUpperCase() +
            profile.family_name.slice(1)
    });

    /**
     * Constructor to filter the profiles with respective group name.
     *
     * @param {string} group - The group name to be filtered.
     * @returns {(profile: Profile) => boolean} - Filter the profiles with respective group name.
     */
    const filterProfile = (group: string): ((profile: Profile) => boolean) => {
        /**
         * Filter the profiles with respective group name.
         *
         * @param {Profile} profile - The profile to be checked.
         * @returns {boolean} - The flag to determine the profile should be included.
         */
        return (profile: Profile): boolean => profile.group.includes(group);
    };

    /**
     * Get the list of the profiles
     *
     * @param {COUNTRY_CODE} countryCode - The country code
     * @returns {SelectDataType[]} - The list of the profiles
     */
    const getProfiles = async (countryCode: COUNTRY_CODE): Promise<Profile[]> => {
        return await listSaleQuery(API, {
            country_code: countryCode
        });
    };

    /**
     * Get the list of the sales for the prospect table
     *
     * @param {COUNTRY_CODE} countryCode - The country code
     * @returns {SelectDataType[]} - The list of the sales for the prospect table
     */
    const getProspectSales = async (countryCode: COUNTRY_CODE): Promise<SelectDataType[]> => {
        const profiles = await getProfiles(countryCode);

        const sales = profiles.filter(filterProfile('sale')).map(mapProfiles);

        return [{ value: 'none', label: 'No owner' }, ...sales];
    };

    /**
     * Get the name of the deal owner of the prospect from the sales list
     *
     * @param {SelectDataType[]} sales - The list of the sales
     * @param {string | null} owner - The deal owner id of the prospect
     * @returns {string} - The deal owner name
     */
    const computeOwnerName = (sales: SelectDataType[], owner?: string | null): string => {
        return computeOwnerRsl(sales, owner);
    };

    /**
     * Get the list of the sales for the dashboard tables
     *
     * @param {COUNTRY_CODE} countryCode - The country code
     * @returns {Promise<SelectDataType[]>} - The list of the sales for the dashboard
     */
    const getDashboardSales = async (countryCode: COUNTRY_CODE): Promise<SelectDataType[]> => {
        const profiles = await getProfiles(countryCode);

        const sales = profiles.filter(filterProfile('sale')).map(mapProfiles);

        return dashboardSalesRsl(sales);
    };

    /**
     * Get the list of the sales for the vendee
     *
     * @param {COUNTRY_CODE} countryCode - The country code
     * @param {string | null} owner - The deal owner of the vendee
     * @returns {Promise<SelectDataType[]>} - The list of the sales for the vendee
     */
    const getVendeeSales = async (
        countryCode: COUNTRY_CODE,
        owner?: string | null
    ): Promise<GetVendeeSalesReturn> => {
        const profiles = await getProfiles(countryCode);

        const sales = profiles.filter(filterProfile('sale')).map(mapProfiles);
        const suggestions = profiles.map(mapProfiles) as MentionData[];

        const salesList = formatSalesRsl(sales);

        if (!owner) {
            return { sales, salesList, suggestions };
        }

        return {
            suggestions,
            sales: vendeeSalesRsl(sales, owner),
            salesList
        };
    };

    return {
        getProspectSales,
        computeOwnerName,
        getDashboardSales,
        getVendeeSales
    };
};

export default salesModel;
